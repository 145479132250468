import styled from 'styled-components';

export const TextFieldContainer = styled.div`
  div {
    position: relative;
    .float {
      position: absolute;
      /* left: 200px; */
      /* right: 15px; */
      font-size: 1.1rem;
      color: #9a9999;
    }
  }
`;
