import styled from 'styled-components';

export const FindContainer = styled.div`
  .form-container {
    width: 100%;
    padding: 0 24px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }
`;
